<template>
  <div>
    <div
      v-show="isShowBigImg"
      class=" modal"
    >
      <div
        class="close-btn cursor"
        @click="closeModal"
      >
        <i class="el-icon-close  white"></i>
      </div>
      <div
        @click="onClickLeft"
        class="left-btn cursor"
      >
        <i class=" el-icon-arrow-left  white"></i>
      </div>
      <div
        @click="onClickRight"
        class="right-btn cursor"
      >
        <i class=" el-icon-arrow-right  white"></i>
      </div>
      <div class="carousel-top">
        <ul class="carousel-top-inner flex" :style="{left: bigBoxLeft+'px'}">
          <li
            class="carousel-top-item shrink relative"
            v-for="item in houseImage"
            :key="item.serverPath"
          >
            <img
              class="all"
              style="object-fit:fill"
              :src="item.serverPath"
              alt=""
            />
            <div
              class="imgName"
              v-if="item.description!='后台上传'"
            >{{item.description}}</div>
          </li>
        </ul>
      </div>
      <div class="carousel-bottom">
        <p class="center size18 white"><span v-if="imgType">{{imgType}}</span>{{current+1 +" / "+ houseImage.length}}</p>
        <div class="carousel-bottom-box">
          <ul class="carousel-bottom-inner flex" :style="{ left: minBoxLeft+'px' }">
            <li
              class="arousel-bottom-item cursor shrink "
              v-for="(item,index) in houseImage"
              :key="item.serverPath"
            >
              <div
                class="no-active"
                v-if="index !== current"
              ></div>
              <img
                class="all"
                :src="item.serverPath"
                alt=""
              />
            </li>
          </ul>
        </div>
        <div
          class="row c_start"
          style="color:#fff;max-width:1100px"
        >
          <span
            style="width:91px"
            class="row s_center"
          >
            <img
              style="width:12px;height:16px;margin-right:5px"
              src="../assets/icon/app_icon_danger_tip.png"
              alt=""
            >
            风险提示：
          </span>
          <p class="flex1">
            以上户型图为房源参考图，其中户型结构及面积并非按国家专业测绘活动所得，与真实现状及产权证明呈现的面积存在差异，仅供参考，请以产权证明或专业机构测量结果为准。
          </p>
        </div>
      </div>
      <!-- <div class="pre-show-danger row c_start">
        

      </div> -->
    </div>
  </div>
</template>

<script>
import tool from "../utils/tool.js";
import $ from "jquery";
const commWidth = 900; //图片宽度
const step = 60; //下面图片
const maxCount = 8; //下边最大显示图片个数
export default {
  props: {
    houseImage: {
      type: Array,
      required: true,
    },
    newImage: {
      type: Object,
    },
    isShowBigImg: {
      type: Boolean,
    },
    imgType: {
      type: String,
    },
  },
  data() {
    return {
      //   houseImage: [],
      current: 0, //控制下边图片的位置
      num: 0, //控制上边图片的位置
      count: 1, //控制下边最大显示
      flag: true,
      bigBoxLeft:0,
      minBoxLeft:0,
    };
  },
  methods: {
    onClickLeft() {
      if (this.flag && this.houseImage.length > 1) {
        let num = this.houseImage.length;
        this.flag = false;
        if (this.current === 0) {
          this.current = num;
          this.count = num - maxCount + 2;
          let left = (num - maxCount) * step;
          $(".carousel-bottom-inner").css("left", `-${left}px`);
        }
        if (this.num === 0) {
          this.num = num;
          let left = num * commWidth;
          $(".carousel-top-inner").css("left", `-${left}px`);
        }
        if (this.current > maxCount - 1 && this.count) {
          this.count--;
        }
        this.num--;
        this.current--;
        let target = -this.num * commWidth;
        this.bigBoxLeft = target;
        this.flag = true;
        // tool.animateX($(".carousel-top-inner")[0], target, () => {
        //   this.flag = true;
        // });
        let parentLeft = parseInt($(".carousel-bottom-inner").css("left"));
        if (parentLeft < 0) {
          let aim = parentLeft + step;
          this.minBoxLeft = aim;
          // tool.animateX($(".carousel-bottom-inner")[0], aim, () => {
          //   this.flag = true;
          // });
        }
      }
    },
    onClickRight() {
      console.log("this.flag>>", this.flag);
      if (this.flag && this.houseImage.length > 1) {
        this.flag = false;
        this.current++;
        this.num++;
        // 最后一张
        if (this.num === this.houseImage.length) {
          this.num = 0;
          $(".carousel-top-inner").css("left", "0px");
        }
        if (this.current === this.houseImage.length) {
          this.count = 1;
          this.current = 0;
          $(".carousel-bottom-inner").css("left", "0px");
        }
        let target = -this.num * commWidth;
        this.bigBoxLeft = target;
        this.flag = true;

        // tool.animateX($(".carousel-top-inner")[0], target, () => {
        //   this.flag = true;
        // });
        if (this.current >= maxCount) {
          let aim = -this.count * step;
          this.minBoxLeft = aim;
          this.count++;
          this.flag = true;
          // tool.animateX($(".carousel-bottom-inner")[0], aim, () => {
          //   this.flag = true;
          // });
        }
      }
    },
    closeModal() {
      this.$emit("close", false);
      console.log("isShowBigImg>>>", this.isShowBigImg);
    },
  },
  watch: {
    houseImage(val, oldVal) {
      let carouselTopEle = document.querySelector(".carousel-top-inner");
      let carouselBottomEle = document.querySelector(".carousel-bottom-inner");

      if (
        carouselTopEle &&
        carouselTopEle.children &&
        carouselTopEle.children.length &&
        carouselTopEle.children.length == val.length
      ) {
        let children = carouselTopEle.children[0].cloneNode(true);
        if (val.length > maxCount) {
          let children_ = carouselBottomEle.children[0].cloneNode(true);
          carouselBottomEle.appendChild(children_);
        }
      }
    },
    newImage(val, old) {
      if (val) {
        this.houseImage.forEach((img, index) => {
          if (img.serverPath == val.serverPath) {
            this.num = index;
            this.current = index;
            // this.count = index + 1;
            let carouselUl = document.querySelector(".carousel-top-inner");
            let target = -this.num * commWidth;
            // carouselUl.style.left = carouselUl.offsetLeft + target + "px";
            // this.onClickRight();
            this.bigBoxLeft = carouselUl.offsetLeft + target
            // console.log('carouselUl.style.left>>>', carouselUl.offsetLeft + target)
          }
        });
        console.log('this.newImage>>>', val)
      }
    },
  },
  created() {
    
  }
};
</script>

<style lang="less" scoped>
.close-btn {
  position: absolute;
  right: 60px;
  top: 40px;
}
.close-btn .el-icon-close {
  font-size: 60px;
}
.left-btn,
.right-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.left-btn i,
.right-btn i {
  font-size: 120px;
}
.left-btn {
  left: 50px;
}
.right-btn {
  right: 50px;
}
.carousel-top {
  width: 900px;
  height: 600px;
  overflow: hidden;
  // margin: 5vh auto;
  // position: relative;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.carousel-top-inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 9999px;
  height: 600px;
}
.carousel-top-item {
  width: 900px;
  height: 100%;
  background: #ffffff9a;
}
.carousel-bottom {
  width: 900px;
  margin: auto;
  // position: relative;

  position: absolute;
  top: calc(50vh + 360px);
  left: 50%;
  transform: translate(-50%, -50%);
}
.carousel-bottom-box {
  width: 500px;
  height: 36px;
  overflow: hidden;
  margin: 20px auto;
  position: relative;
}
.carousel-bottom-inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 9999px;
  height: 100%;
}
.arousel-bottom-item {
  width: 48px;
  height: 36px;
  margin-right: 10px;
  position: relative;
}
.no-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.active {
  background-color: transparent;
}
.imgName {
  text-align: center;
  margin-top: 10px;
  color: #fff;
  position: absolute;
  bottom: 1px;
  left: 1px;

  display: inline-block;
  line-height: 32px;
  text-align: center;
  padding: 0 11px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 12px;
}

.pre-show-danger {
}
</style>