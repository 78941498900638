import request from '../common/request'
import { API_URL } from '../common/Url'
export default {
    // 获取二手房/租房输入参数
    // listEstateByName(params) {
    //     return request({
    //         method: 'GET',
    //         url: API_URL.ESTATE_LIST_GET,
    //         params
    //     })
    // },
    // //新房列表
    // getNewEstatePage(data) {
    //     return request({
    //         method: 'POST',
    //         url: API_URL.NEW_ESTATE_PAGE_LIST,
    //         data
    //     })
    // },

    //获取二手房详情
    getRealHouseDetail(guid) {
        return request({
            method: 'GET',
            url: API_URL.REALHOUSE_BYID_GET.replace("{guid}", guid),
        })
    },
    //获取店铺房源详情
    getHouseDetail(guid) {
        return request({
            method: 'GET',
            url: API_URL.CUSTOEMR_AGENTHOUSE_DETAIL.replace("{guid}", guid),
        })
    },
    //获取二手房图片
    getHouseresource(guid) {
        return request({
            method: 'GET',
            url: API_URL.RESOURCE_GET.replace("{guid}", guid),
        })
    },
    //获取房源带看反馈
    getTakeFeedback(params) {
        return request({
            method: 'GET',
            url: API_URL.TAKE_FEED_BACK,
            params
        })
    },
    //获取房源动态
    getRealHouseDynamicById(params) {
        return request({
            method: 'GET',
            url: API_URL.REALHOUSE_DYNAMIC_GET,
            params
        })
    },
    //获取房源成交历史
    loadHistoryDeal(params) {
        return request({
            method: 'GET',
            url: API_URL.HOUSE_HISTORY_DEAL,
            params
        })
    },
    //获取房源周边推荐
    loadSecondHouse(params) {
        return request({
            method: 'GET',
            url: API_URL.GET_RECOMMEND_HOUSE,
            params
        })
    },
    //获取小区详情
    getEstateById(guid) {
        return request({
            method: 'GET',
            url: API_URL.ESTATE_GET_BYUD.replace("{guid}", guid),
        })
    },
    //获取推荐经纪人
    getRealHouseRecommendById(data) {
        return request({
            method: 'POST',
            url: API_URL.REALHOUSE_RECOMMEND_AGENTV3,
            data
        })
    },
    // 租房附近房源
    getNeighborhoodHouse(params) {
        return request({
            method: 'GET',
            url: API_URL.GET_RENT_NEIGHBORHOOD,
            params
        })
    },
    //同价位房源
    getSameHouseByEstate(params) {
        return request({
            method: 'GET',
            url: API_URL.SAME_ESTATE_HOUSE_LIST,
            params
        })
    }
}