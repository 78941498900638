import request from '../common/request'
import { API_URL } from '../common/Url'
export default {
    loadCityList (name) {
        return request({
            method: 'GET',
            url: API_URL.CITYLIST,
            params: { name }
        })
    },
    loadSecondHouseParam (cityCode) {
        return request({
            method: 'GET',
            url: API_URL.SECOND_HOUSE_PARAM,
            params: { cityCode }
        })
    },
    loadOldEstateParam (cityCode) {
        return request({
            method: 'GET',
            url: API_URL.OLD_ESTATE_PARAM,
            params: { cityCode }
        })
    },
    loadNewEstateParam (cityCode) {
        return request({
            method: 'GET',
            url: API_URL.NEW_ESTATE_PARAM,
            params: { cityCode }
        })
    },
    loadQrCodePath (params) {
        return request({
            method: 'GET',
            url: API_URL.QR_CODE,
            params
        })
    },
    loadRentHouseParam(cityCode) {
        return request({
            method: 'GET',
            url: API_URL.RENT_HOUSE_PARAM,
            params: { cityCode }
        }) 
    }
}